import { render, staticRenderFns } from "./Social.vue?vue&type=template&id=6a8f3bb2&scoped=true&"
import script from "./Social.vue?vue&type=script&lang=js&"
export * from "./Social.vue?vue&type=script&lang=js&"
import style0 from "./Social.vue?vue&type=style&index=0&id=6a8f3bb2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a8f3bb2",
  null
  
)

export default component.exports