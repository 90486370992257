<template>
  <div
    v-if="data.fields"
  >
    <div class="component-wrapper" :class="{'bg-image': data.fields.image}">
      <h4 v-if="data.fields.headline">{{data.fields.subtitle}}</h4>
      <div v-html="mdToHTML(data.fields.description)" v-if="data.fields.description" class="description"></div>
      <div class="button-wrapper">
        <div class="social-item" v-if="data.fields.whatsApp">
          <div
            @click="openLink(data.fields.whatsApp)"
            class="whatsapp-wrapper bounce-entry"
          >
            <Icons
              class="icon mobile"
              icon="whatsapp"
              size="32"
              :color="'rgba(255,255,255,0.9)'"
            ></Icons>
            <Icons
              class="icon desktop"
              icon="whatsapp"
              size="48"
              :color="'rgba(255,255,255,0.9)'"
            ></Icons>
          </div>
          <div class="social-content desktop">
            +57 3057112020
          </div>
        </div>
        <div class="social-item" v-if="data.fields.facebook">
          <div
            @click="openLink(`https://facebook.com/${data.fields.facebook}`)"
            class="facebook-wrapper bounce-entry"
          >
            <Icons
              class="icon mobile"
              icon="facebook"
              size="32"
              :color="'rgba(255,255,255,0.9)'"
            ></Icons>
            <Icons
              class="icon desktop"
              icon="facebook"
              size="48"
              :color="'rgba(255,255,255,0.9)'"
            ></Icons>
          </div>
          <div class="social-content desktop">
            {{data.fields.facebook}}
          </div>
        </div>
        <div class="social-item" v-if="data.fields.instagram">
          <div
            @click="openLink(`https://instagram.com/${data.fields.instagram}`)"
            class="instagram-wrapper bounce-entry"
          >
            <Icons
              class="icon mobile"
              icon="instagram"
              size="32"
              :color="'rgba(255,255,255,0.9)'"
            ></Icons>
            <Icons
              class="icon desktop"
              icon="instagram"
              size="48"
              :color="'rgba(255,255,255,0.9)'"
            ></Icons>
          </div>
          <div class="social-content desktop">
            @{{data.fields.instagram}}
          </div>
        </div>
        <div class="social-item" v-if="data.fields.email">
          <div
            @click="openLink(`mailto:${data.fields.email}`)"
            class="email-wrapper bounce-entry"
          >
            <Icons
              class="icon mobile"
              icon="email"
              size="32"
              :color="'rgba(255,255,255,0.9)'"
            ></Icons>
            <Icons
              class="icon desktop"
              icon="email"
              size="48"
              :color="'rgba(255,255,255,0.9)'"
            ></Icons>
          </div>
          <div class="social-content desktop">
            {{data.fields.email}}
          </div>
        </div>
        <div class="social-item" v-if="data.fields.sensataFormIcon">
          <div
            @click="$router.push(`/s/${data.fields.sensataFormIcon}?source=${windowLocation}`)"
            class="sensata-wrapper bounce-entry"
          >
            <Icons
              class="icon mobile"
              icon="sensata-dots"
              size="36"
              :color="'rgba(255,255,255,0.9)'"
            ></Icons>
            <Icons
              class="icon desktop"
              icon="sensata-dots"
              size="48"
              :color="'rgba(255,255,255,0.9)'"
            ></Icons>
          </div>
          <div class="social-content desktop">
            Sensata
          </div>
        </div>
      </div>
    </div>
    <iframe v-if="data.fields.gmaps" class="mobile" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.4354128929717!2d-74.03467958523767!3d4.694158796590404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9b479c145a05%3A0xf8ad1f25f34a2cd!2sSensata%20UX%20Research!5e0!3m2!1sen!2sco!4v1581894626296!5m2!1sen!2sco" width="100%" height="300" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
    <div class="gmap-wrapper desktop" v-if="data.fields.gmaps">
      <iframe class="" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.4354128929717!2d-74.03467958523767!3d4.694158796590404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9b479c145a05%3A0xf8ad1f25f34a2cd!2sSensata%20UX%20Research!5e0!3m2!1sen!2sco!4v1581894626296!5m2!1sen!2sco" width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
    </div>
  </div>
</template>

<script>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

export default {
  name: 'Social',

  transition: 'page',

  props: [ 'data' ],

  data () {
    return {
      standoutIcon: ''
    }
  },

  computed: {
    windowLocation () {
      return window.location.href
    }
  },

  components: {
    Icons: () => import('Components/base/Icons')
  },

  methods: {
    openLink (target) {
      window.open(target, '_blank')
    },
    mdToHTML (markdown) {
      return documentToHtmlString(markdown)
    },
  }
}
</script>

<style scoped lang="scss">
.component-wrapper {
  padding: 1em 1em;
  position: relative;
  @include breakpoint($tablet) {
    padding-top: 0;
  }
}

.button-wrapper {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.gmap-wrapper {
}

.social-item {
  margin: 0.5em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background 0.2s ease-in-out;
  @include breakpoint($tablet) {
    min-width: 160px;
    padding: 1em;
    border-radius: 10px;
    &:hover {
      cursor: pointer;
      background-color: $gray-light;
    }
  }
}
.whatsapp-wrapper,
.email-wrapper,
.sensata-wrapper,
.facebook-wrapper,
.instagram-wrapper {
  background: #4064AD;
  border-radius: 100px;
  width: 60px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  margin-bottom: 1em;
  @include breakpoint($tablet) {
    width: 80px;
    height: 75px;
  }
}
.whatsapp-wrapper {
  background: rgb(96, 187, 77);  
}
.email-wrapper {
  background: rgb(220, 27, 30);  
}
.sensata-wrapper {
  background: rgba(#f7f7f7, 0.9);  
}
.instagram-wrapper {
  background-color: #dd2a7b;
}
.mobile {
  @include breakpoint($tablet) {
    display: none;
  }
}
.desktop {
  display: none;
  @include breakpoint($tablet) {
    display: block;
  }
}
.description {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
</style>
